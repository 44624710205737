@import '../../styles/utils.scss';

.el {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  .modal {
    width: 100%;
    height: 100%;
    padding: 30px 60px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    position: relative;
    @include desktop {
      padding: 30px;
    }
    @include tablet {
      height: 100%;
      padding: 0 20px;
    }
  }
  .close {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 1;
    position: absolute;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
    top: 30px;
    right: 30px;
    @include tablet {
      z-index: 5;
    }
    &:hover {
      transform: scale(1.1);
    }
    span {
      width: 26px;
      height: 1px;
      background-color: #272560;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      &:first-child {
          transform: translate(-50%) rotate(45deg);
      }
      &:last-child {
          transform: translate(-50%) rotate(-45deg);
      }
    }
  }
  .videoFrame {
    position: relative;
    width: 100%;
    @include tablet {
      z-index: 1;
    }
    &.inner {
      width: 100%;
    }
    .vimeoPlayer {
      max-width: 1200px;
      margin: 0 auto;
      @include desktop {
        width: 100%;
      }
      @media screen and (max-width:991px) and (orientation: landscape) {
        max-width: 60%;
      }
      iframe {
        width: 100%;
      }
      & > div {
        width: 100%;
      }
    }
  }
}