@import '../../styles/utils.scss';

.container {
  background: $color-blue;
  bottom: 0px;
  align-items: baseline;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
  padding: 40px 30px;
  @media screen and (min-width: 1440px) {
    padding-left: calc((100% - 1380px) / 2);
    padding-right: calc((100% - 1380px) / 2);
  }
  @include mobile {
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;
  }
}

.contentWrap {
  position: relative;
  flex: 1 0 360px;
  font-size: 14px;
  line-height: 20px;
  @media screen and (max-width: 770px) {
    flex: 1 0 280px;
  }
  a {
    color: #fff;
    text-decoration: underline;
  }
}

.overlay {
  position: relative;
}

.buttonsWrap {
  position: relative;
  @media screen and (max-width: 770px) {
    text-align: center;
    width: 100%;
    margin-top: 20px;
  }
  button {
    padding: 15px 49px;
    background: $color-blue-80;
    border-radius: 40px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    @media screen and (max-width: 770px) {
      margin: 0;
    }
  }
  .declineBtn {
    background: none;
    @media screen and (max-width: 770px) {
      padding: 15px 30px;
    }
  }
}

