@import '../../styles/utils.scss';

.el {
  .wrapper {
    position: relative;
    width: 400px;
    border-bottom: 2px solid $color-blue-25;
    padding-bottom: 14px;
    @include tablet {
      width: 350px;
    }
    @include mobile {
      width: 100%;
    }
    & > form {
      display: flex;
      justify-content: space-between;
      .fieldWrapper {
        &:first-child {
          flex-grow: 2;
        }
      }
    }
  }
  .form-input {
    width: 100%;
    background: transparent;
    border: none;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.05em;
    color: #fff;
    padding: 3px 0;
  }
  .submitButton {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.05em;
    padding: 3px 0;
    color: #fff;
    cursor: pointer;
  }
  .errorText {
    margin-top: 5px;
  }
}